    .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the progress bar and text vertically */
    align-items: center; /* Center the progress bar and text horizontally */
    width: 100%;
    height: 90vh;
    position: relative; /* Keep it relative for absolute positioning of the image */
    overflow: hidden;
  }

  
  /* Bottom-right image container using flexbox */
  .loading-image-container {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 10px;
  }
  
  .switch-image {
    width: 100px;
    height: auto;
  }
  
  .progress-bar {
    position: absolute;
    top: 35%;
    left: 0;
    height: 2px;
    background: #776f60;
  }
  
  .count {
    position: absolute;
    top: 22%;
    width: 100%;
    text-align: center;
    font-weight: 100;
    font-size: 3em;
    color: #776f60;
  }
  
  .coming-soon {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 3em;
    color: #776f60;
  }
  @media (max-width:650px) {
    .coming-soon{
        font-size: 1.5em;
    }
    .loading-container{
        height: 65vh;
    }
  }
  .switch-image {
    width: 80%;
    height: auto;
  }
  