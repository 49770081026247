.services-container {
    background-color: #f6f6f4;
    padding: 20px;
    position: relative;
}

.cards-wrapper {
    width: 100%;
    overflow-x: scroll; /* Allow horizontal scroll */
    scroll-snap-type: x mandatory; /* Enable scroll snap */
    display: flex;
    position: relative;
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on touch devices */
    z-index: 10;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.cards-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px ;
    padding-top: 10px;
    transition: transform 0.5s ease;
}

.service-card {
    background-color: white;
    flex-shrink: 0;
    width: 240px; /* Default card width for small screens */
    
    margin: 0 30px; /* Small gap between cards */
    overflow: visible;
    position: relative; /* For absolutely positioned button */
    transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
}

@media (min-width: 740px) {
    .service-card {
        width: auto; /* Larger width on bigger screens */
        height: 500px;
        
    }
}

.service-image {
    width: auto;
    height: 80%;
    object-fit: cover;
}

/* Service Content */
.service-content {
    text-align: left;
    padding: 1.5rem;
   
}



.service-content p {
    margin-top: 0.5rem;
    
}

/* Styles for Read More Button */
.read-more {
    display: none;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem 1.5rem;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 10;
}

.service-card:hover .read-more {
    display: block;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
}

/* Card hover effect */
.service-card:hover {
    transform: translateY(-8px);
}

.service-card:active {
    transform: translateY(-3px);
}

/* Arrows for Navigation */
.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(119, 111, 96, 0.513); /* Semi-transparent background */
    border: 2px solid white;
    cursor: pointer;
    color: white;
    
    border-radius: 50%;
    z-index: 20;
    width: 70px;
    height: 70px;
}
@media (max-width: 770px) {
    .arrow { 
        width: 40px;
        height: 40px;  
    }
    .read-more{
        padding: 0.5rem 0.5rem;
    }
   
}

/* Position left and right arrows */
.left-arrow {
    padding: 8px;
    left: 10px; /* Position left arrow */
}

.right-arrow {
    padding: 10px;
    right: 10px; /* Position right arrow */
}

/* Hover effect for the arrows */
.arrow:hover {
    background-color: rgba(119, 111, 96, 0.9); /* Darken on hover */
}

/* Arrow Disabled State */
.arrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
