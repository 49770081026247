/* Overview Component */
.overview__image-text-section {
  display: flex;
  width: 100%;
}

.overview__text-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
}
@media (max-width:750px){
 .overview__text-container{
  align-items: start;
  
 }
 .overview__text-content{
  top: 50px;
  left: 20px;
 }
}

.overview__text-content {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(0);
  animation-fill-mode: backwards;
  
  overflow: visible;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity, transform;
}

.overview__fade-in {
  visibility: visible;
  animation: overview__slideIn 1s ease forwards;
  -webkit-animation: overview__slideIn 1s ease forwards;
}

.overview__fade-out {
  animation: overview__fadeOut 0s ease forwards;
  -webkit-animation: overview__fadeOut 0s ease forwards;
}

@keyframes overview__slideIn {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  80% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes overview__slideIn {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  80% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes overview__fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes overview__fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.overview__image-container {
  width: 80%;
  flex: 3;
  position: relative;
  overflow: hidden;
}

.overview__image-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
}

.overview__image-content.fade-in {
  opacity: 1;
  animation: overview__fadeIn 1s ease forwards;
}

.overview__image-content.fade-out {
  opacity: 0;
  animation: overview__fadeOut 0s ease forwards;
}

@keyframes overview__fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
