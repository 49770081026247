.nav-link {
    position: relative;
    padding-bottom: 5px;
    text-decoration: none;
  }
  
  .nav-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #2f2f2f; /* Underline color */
    transition: width 0.5s ease-in-out;
  }
  
  .nav-link:hover::before {
    width: 100%; /* Expand to full width on hover */
  }
  
  /* Remove transition when hover ends */
  .nav-link:not(:hover)::before {
    transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
    width: 0;
    left: 100%; /* Move the underline to the right */
  }